import { Paper, Divider, Flex } from "@mantine/core";
import { GlobalContext } from "../../context/GlobalContext";
import { useTranslation } from "react-i18next";
import "./QuoteSummary.scss";
import { React, useContext, useEffect, useState } from "react";
import { Paths } from "../../models/constants/paths";
import { useLocation } from "react-router-dom";
import { Tooltip } from "../Tooltip/Tooltip";

export const QuoteSummary = (props) => {
  const { t } = useTranslation();
  const { context } = useContext(GlobalContext);
  const location = useLocation();
  const currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [surcharges, setSurcharges] = useState([]);
  const [surchargeTotal, setSurchargeTotal] = useState(0);

  const deductibles = [
    { name: t("after-six-months"), price: "$200.00" },
    { name: t("after-twelve-months"), price: "$100.00" },
    { name: t("after-twenty-four-months"), price: "$0.00" },
  ];

  const initialRegistrationFee = props.final ? parseFloat(context.final_quote.one_time_payment) : parseFloat(context.initial_quote.one_time_payment);
  const registrationFeeDiscount = props.final ? parseFloat(context.final_quote.one_time_payment_discount_amount) : parseFloat(context.initial_quote.one_time_payment_discount_amount);
  const finalRegistrationFee = props.final ? parseFloat(context.final_quote.discounted_one_time_payment) : parseFloat(context.initial_quote.discounted_one_time_payment);
  const baseCoverage = props.final ? parseFloat(context.final_quote.base_rate) : parseFloat(context.initial_quote.base_rate);
  const subtotalTaxes = parseFloat(context.final_quote.subtotal_tax);
  const finalTaxes =
    parseFloat(context.final_quote.one_time_payment_tax) +
    parseFloat(context.final_quote.subtotal_tax);

  useEffect(() => {
    calculateSurcharges();
    setIsLoading(false);
  }, []);

  const lineItem = (name, price, style) => {
    if (style === undefined) {
      style = "";
    }
    return (
      <Flex
        gap="sm"
        align="center"
        justify="space-between"
        direction="row"
      >
        <div className={`surcharge${style}Text`}>{name}</div>
        <div className={`surcharge${style}Text alignRight`}>{price}</div>
      </Flex>
    );
  };

  function calculateSurcharges() {
    let selectedSurcharges = surcharges;
    let selectedSurchargeTotal = surchargeTotal;
    if (context.surcharges.diesel_surcharge_value) {
      selectedSurcharges.push({
        name: t("surcharge-diesel"),
        price: context.surcharges.diesel_surcharge_value,
      });
      selectedSurchargeTotal += context.surcharges.diesel_surcharge_value;
    }
    if (context.surcharges.four_by_four_surcharge_value) {
      selectedSurcharges.push({
        name: t("surcharge-4x4-awd"),
        price: context.surcharges.four_by_four_surcharge_value,
      });
      selectedSurchargeTotal += context.surcharges.four_by_four_surcharge_value;
    }
    if (context.surcharges.turbo_surcharge_value) {
      selectedSurcharges.push({
        name: t("surcharge-turbo-super"),
        price: context.surcharges.turbo_surcharge_value,
      });
      selectedSurchargeTotal += context.surcharges.turbo_surcharge_value;
    }
    setSurcharges(selectedSurcharges);
    setSurchargeTotal(selectedSurchargeTotal);
  }

  function renderSurcharges() {
    //Filtering out surcharges that have a price value equal to 0.
    let filteredSurcharges = surcharges.filter((surcharge) => {
      return parseFloat(surcharge.price) > 0;
    });
    const map = new Map(filteredSurcharges.map(surcharge => [surcharge.name, surcharge]));
    filteredSurcharges = [...map.values()];
    return filteredSurcharges.length ? (
      <>
        <div className="surchargeSubtext">{t("surcharges")}</div>
        <div style={{ marginBottom: "16px" }}>
          {filteredSurcharges.map((surcharge, index) => {
            return lineItem(
              surcharge.name,
              currency.format(surcharge.price)
            );
          })}
        </div>
      </>
    ) : null;
  }

  function renderMonthlyTaxes() {
    return location.pathname === Paths.Welcome ? (
      <div style={{ marginBottom: "16px" }}>
        {lineItem(
          t("subtotal"),
          currency.format(parseFloat(baseCoverage) + parseFloat(surchargeTotal))
        )}
        {lineItem(t("taxes"), currency.format(subtotalTaxes))}
      </div>
    ) : null;
  }

  function renderTaxes() {
    return location.pathname === Paths.Welcome ? (
      <div style={{ marginBottom: "16px" }}>
        {lineItem(
          t("subtotal"),
          currency.format(
            parseFloat(baseCoverage) +
            parseFloat(surchargeTotal) +
            parseFloat(finalRegistrationFee)
          )
        )}
        {lineItem(t("taxes"), currency.format(finalTaxes))}
      </div>
    ) : null;
  }

  function calculateDueToday() {
    const dueTodayText =
      location.pathname === Paths.Welcome ? t("paid-today") : t("due-today");
    let dueToday =
      parseFloat(finalRegistrationFee) +
      parseFloat(baseCoverage) +
      parseFloat(surchargeTotal);
    if (location.pathname === Paths.Welcome) {
      dueToday += parseFloat(finalTaxes);
    }
    return lineItem(dueTodayText, currency.format(dueToday), "Bold");
  }

  function calculateDueMonthly() {
    let dueMonthly = parseFloat(baseCoverage) + parseFloat(surchargeTotal);
    if (location.pathname === Paths.Welcome) {
      dueMonthly += parseFloat(subtotalTaxes);
    }
    return lineItem(t("due-monthly"), currency.format(dueMonthly), "Bold");
  }

  function renderTaxDisclaimer() {
    return location.pathname !== Paths.Welcome ? (
      <div className="text-box-subtext" style={{ marginTop: "8px" }}>
        {t("taxes-may-apply")}
      </div>
    ) : null;
  }

  function renderHeader() {
    switch (location.pathname) {
      case Paths.FinalEstimatedQuote:
        return <div className="text-box-header">{t("your-quote")}</div>;
      case Paths.FinalQuote:
        return (
          <div className="text-box-header">{t("your-vin-verified-quote")}</div>
        );
      case Paths.Welcome:
        return (
          <div className="text-box-header">{t("your-payment-summary")}</div>
        );
    }
  }

  function renderPopover() {
    return location.pathname === Paths.FinalEstimatedQuote ? null : (
      <Tooltip label={t("calculated-based-on-vin")} marginBottom={"8px"} />
    );
  }

  return isLoading ? null : (
    <Paper
      shadow="0"
      radius="md"
      p="16px"
      withBorder
      style={{ margin: "32px 0px 16px 0px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {renderHeader()}
        {renderPopover()}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="header">
          <div className="header_1">
            <div className="header_1_as-low-as">
              {currency.format(
                parseFloat(baseCoverage) + parseFloat(surchargeTotal)
              )}
            </div>
          </div>
        </div>
        <div className="subheader" style={{ display: "flex" }}>
          <div className="subheader_as-low-as">
            &nbsp;&nbsp;{t("per-month")}
          </div>
        </div>
      </div>
      {surcharges.length ? <Divider /> : null}
      {surcharges.length ? (
        <div className="quoteDivider">
          {lineItem(t("base-coverage"), currency.format(baseCoverage))}
        </div>
      ) : null}
      {renderSurcharges()}
      {renderMonthlyTaxes()}
      {calculateDueMonthly()}
      {renderTaxDisclaimer()}
      <div className="quoteDivider">
        <Divider />
      </div>
      <div style={{ marginBottom: "16px" }}>
        {lineItem(
          t("first-months-coverage"),
          currency.format(parseFloat(baseCoverage) + parseFloat(surchargeTotal))
        )}
      </div>
      {renderTaxes()}
      {calculateDueToday()}
      {renderTaxDisclaimer()}
      <div className="quoteDivider">
        <Divider />
      </div>
      {lineItem(t("starting-deductible"), "$400.00", "Bold")}
      <div style={{ marginBottom: "16px" }}>
        {deductibles.map((deductible) => {
          return lineItem(deductible.name, deductible.price);
        })}
      </div>
      <div className="text-box-subtext">
        {t("deductible-valid")}
      </div>
    </Paper>
  );
};
